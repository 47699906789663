import React from 'react';

import BaseLayout from '../components/layouts/BaseLayout';
import Stage from '../components/layouts/Stage';
import StageIntro from '../components/elements/StageIntro';
import { Section } from '../components/layouts/Containers';
import { Link } from '../components/elements/ButtonsAndLinks';
import { P } from '../components/elements/Typography';

const NotFoundPage = () => (
    <BaseLayout title="404 - Seite wurde nicht gefunden" description="Seite wurde nicht gefunden">
        <Stage>
            <StageIntro headline={{ text: '404 - Seite wurde nicht gefunden' }} />
        </Stage>
        <Section container="s">
            <P>Leider wurde die von Ihnen aufgerufene URL nicht gefunden.</P>
            <Link gap="xxl" to="/">
                Zurück zur Startseite
            </Link>
        </Section>
    </BaseLayout>
);

export default NotFoundPage;
